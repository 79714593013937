import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";

import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';

export const Footer = () => {
  return (
    
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
                <a href="https://twitter.com/DarkZuckSol"><img src={navIcon1} alt="" /></a>
                <a href="https://t.me/DarkZuck"><img src={navIcon2} alt="" /></a>
                <a href="https://dexscreener.com/"><img src={navIcon3} alt="" /></a>
                <a href="https://solscan.io/token/ZucKyq84QDTELTxHHbtv9dZeqkHjcx2KQhfsC78zk4C"><img src={navIcon4} alt="" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
