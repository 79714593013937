import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/ceo.png";
import projImg2 from "../assets/img/coo.png";
import projImg3 from "../assets/img/cto.png";


import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Team = () => {

  const teams = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    }
  ];

  return (
    <section className="team" id="teams">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Doxxed Team</h2>
                <p></p>
                <Row>
                        {
                          teams.map((team, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...team}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
