import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/thetech.png";
import projImg2 from "../assets/img/hq.png";


import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Tech = () => {

  const teams = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    }
  ];

  return (
    <section className="tech" id="techs">
      <Container>
        <Row>
          <Col size={12} style={{display: 'flex', justifyContent: 'center'}}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>High Class Technology</h2>
                <p></p>
                <Row>
                        {
                          teams.map((tech, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...tech}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
