import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/faceboard.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Dark Zuckerberg</h2>
                
                <span className="tagline">Ethiopia's #1 Entrepreneur</span>
                  <p>
                  Hi, I'm Dark Zuckerberg, Mark's second cousin. I am CEO of Faceboard, Ethiopia's largest tech company. The office is only a 28km run from my village. 
                  <p/>
                  <b>Company Pricing</b>: 1 account, 1 cow. 
                  <br/>
                  <b>Seed Round Investment</b>: 5 cattle, 3 chickens, 1 pig.
                  <br/>
                  <b>Partners</b>: Blackrock, Ethiopian military.  
                  <br/>
                  <p>

                  </p>
                  <p>
                  Click <a href="https://twitter.com/AlexJayeSINGER/status/1755256818555621411/photo/1">here</a> to see my origin story.
                  </p>

                  <p>
                  <p>
                    My major investment is in <b>$ZUCK</b>.
                  <br/>
                  <b>CA</b>: ZucKyq84QDTELTxHHbtv9dZeqkHjcx2KQhfsC78zk4C

                  </p>
                  </p>

                  </p>

                  <button onClick={() => console.log('connect')}>Uniswap Coming Soon<ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
